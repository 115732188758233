/* * {
  box-sizing: border-box;
} */

.zoom {
  padding: 4em;
  transition: transform 0.5s;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(3.5);
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
}
