.MuiGrid-grid-xs-3 {
  height: 800px;
  /* overflow-y: scroll; */
}

.MuiGrid-grid-xs-9 {
  /* height: 801px; */
  overflow-y: auto;
}

.MuiGrid-grid-xs-11 {
  word-break: break-all;
  white-space: pre-wrap;
}

.columnClass {
  flex: 1;
  padding: 1em;
  /* border: solid #00376a; */
  color: white;
}
.headerClass {
  height: 100%;
  vertical-align: super;
  font-weight: bold;
  font-size: 17px;
}
.dateClass {
  font-size: 8px;
}
.chatHeaderClass {
  /* position: sticky; */
  top: 0;
  /* background-color: #00376a; */
}
.leftClass {
  direction: ltr;
}
.rightClass {
  direction: rtl;
}
.dateBoxClass {
  box-sizing: border-box;
  width: 300px;
  border: 1px solid white;
  display: inline;
  justify-content: center;
  border-radius: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: gray;
  color: white;
}
.chatBoxRightClass {
  border-radius: 25px;
  display: flex;
  justify-content: right;
  background-color: #d1cccc;
  padding: 5px;
}
.chatBoxLeftClass {
  border-radius: 25px;
  display: flex;
  justify-content: left;
  background-color: #04acf1;
  color: white;
  padding: 5px;
}
.chatBoxMainClass {
  max-height: 660px;
  overflow-y: scroll;
  /* background-color: #a3a5a8; */
}
