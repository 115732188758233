.chip,
.singleChip {
  display: grid;
}
.App {
  font-family: sans-serif;
  text-align: center;
  padding: 15px;
  background: white;
  font-size: 10px;
}
.DatePicker_dateLabel__tZsmh {
  font-size: 12px;
}

.DatePicker_dateLabel__KeEIO {
  font-size: 12px;
}

.imageClass {
  background-color: white;
  display: block;
  margin-left: 7em;
  margin-right: auto;
  width: 50%;
  height: 30em;
}
.suggestions {
  margin-bottom: 10px; /* Add margin at the bottom */
  /* border: 1px solid #0d6efd; */
}

.input-container {
  display: block; /* Force the input container to be on a new line */
}
